import React, { createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeConfig } from "@eureka-ui/theme";

import Keycloak from "keycloak-js";


let initOptions = {
    realm: "spectrum",
    url: "https://keycloak.eureka.ai/auth",
    "ssl-required": "external",
    clientId: "pinpoint-mui",
    "public-client": true,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0
}

let keycloak = new Keycloak(initOptions);
const GlobalDataProvider = createContext();
let globalData = null

export { keycloak }

keycloak.init({
    onLoad: "login-required",
    checkLoginIframe: false,
}).success((auth) => {

    if (!auth) {
        window.location.reload();
    } else {
        console.info("Authenticated");
        globalData = {
            clientId: keycloak.tokenParsed.mui_params.clientId,
            privateKey: keycloak.tokenParsed.mui_params.privateKey,
            countries: keycloak.tokenParsed.mui_params.countries,
            clientName: keycloak.tokenParsed.mui_params.clientName,
            giveName: keycloak.tokenParsed.given_name,
            familyName: keycloak.tokenParsed.family_name
        }
    }

    ReactDOM.render(
        <React.StrictMode>
            <GlobalDataProvider.Provider value={{globalData}}>
                <ThemeConfig>
                    <App />
                </ThemeConfig>
            </GlobalDataProvider.Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );

    localStorage.setItem("react-token", keycloak.token);
    localStorage.setItem("react-refresh-token", keycloak.refreshToken);

    setTimeout(() => {
        keycloak.updateToken(70).success((refreshed) => {
            if (refreshed) {
                console.debug('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).error(() => {
            console.error('Failed to refresh token');
        });


    }, 60000)

}).error((e) => {
    console.error("Authenticated Failed: " + e.message);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { GlobalDataProvider };