import React, {useContext, useRef, useState} from "react";
import logoEureka from "../img/eureka-logo.png";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import PopoverMenu from "./PopoverMenu";
import {Link} from "react-router-dom";
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    Button,
    Box,
    Divider,
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

import {GlobalDataProvider, keycloak} from "../index";
import Utils from "../helper/utils";
import RenderFlag from "../helper/RenderFlag";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: "#515151",
        paddingLeft: "10px"
    },
    sectionDesktop: {
        color:"#515151"
    },
}));

const Header = (props) => {
    const classes = useStyles();

    const { globalData } = useContext(GlobalDataProvider);
    const anchorRef = useRef(null);
    const anchorRefNotification = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [isOpenNotification, setOpenNotification] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenNotification = () => {
        setOpenNotification(true)
    }

    const handleCloseNotification = () => {
        setOpenNotification(false)
    }

    return (
        <AppBar position="static" style={{background: "#FFFFFF", marginBottom: "30px"}}>
            <Toolbar>
                <img src={logoEureka} width="148" alt="Eureka Logo" />
                <Typography variant="h4" className={classes.title}>
                </Typography>
                <div className={classes.sectionDesktop}>
                    <IconButton
                        ref={anchorRefNotification}
                        edge="end"
                        aria-label="notification"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleOpenNotification}
                    >
                        <Badge badgeContent={props.notifList ? props.notifList.length : 0} color="error" >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <IconButton
                        ref={anchorRef}
                        edge="end"
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleOpen}
                    >
                        <Avatar>
                            <AccountCircle />
                        </Avatar>
                    </IconButton>

                    <PopoverMenu
                        width={220}
                        open={isOpen}
                        onClose={handleClose}
                        anchorEl={anchorRef.current}
                    >
                        <Box sx={{ my: 2, px: 2.5 }}>
                            <Typography variant="subtitle1" noWrap>
                                {globalData.giveName} {globalData.familyName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                                {globalData?.clientName}
                            </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ p: 2, pt: 1.5 }}>
                            <Button
                                color="inherit"
                                variant="outlined"
                                fullWidth
                                onClick={() => {
                                    keycloak.logout()
                                }}
                            >
                                Logout
                            </Button>
                        </Box>
                    </PopoverMenu>

                    <PopoverMenu
                        width={370}
                        open={isOpenNotification}
                        onClose={handleCloseNotification}
                        anchorEl={anchorRefNotification.current}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1">Recently Updated</Typography>
                            </Box>
                        </Box>

                        <Divider />
                        <List dense={true}>
                            {
                                props.notifList && props.notifList.map((item) => {
                                    return (
                                        <ListItem>
                                            <ListItemIcon>
                                                <RenderFlag country={item.country} width={26}  />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={Utils.camelToCapital(item.report)}
                                                secondary={`${item.detail.name} - ${item.detail.month}`}
                                            />
                                            <ListItemSecondaryAction>
                                                <GetAppIcon onClick={() => props.handleDownload(`/data/${props.client}/csv/${item.detail.link}`, item.detail.link) } style={{cursor: "pointer"}}/>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </PopoverMenu>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default  Header