import React from "react";

import flagIndonesia from "../img/flagindonesia.png"
import flagThailand from "../img/flagthailand.png"
import flagMalaysia from "../img/flagmalaysia.png"
import flagSingapore from "../img/flagsingapore.png"
import flagVietnam from "../img/flagvietnam.png"

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    flagStyle: {
        margin: "0 auto"
    }
});

const RenderFlag = (props) => {
    const classes = useStyles();

    switch (props.country) {
        case "indonesia" :
            return <img src={flagIndonesia} width={props.width} height={props.height} alt={props.country} className={classes.flagStyle} />
        case "indonesiaIOH" :
            return <img src={flagIndonesia} width={props.width} height={props.height} alt={props.country} className={classes.flagStyle} />
        case "thailand" :
            return <img src={flagThailand} width={props.width} height={props.height} alt={props.country} className={classes.flagStyle} />
        case "malaysia" :
            return <img src={flagMalaysia} width={props.width} height={props.height} alt={props.country} className={classes.flagStyle} />
        case "singapore" :
            return <img src={flagSingapore} width={props.width} height={props.height} alt={props.country} className={classes.flagStyle} />
        case "vietnam" :
            return <img src={flagVietnam} width={props.width} height={props.height} alt={props.country} className={classes.flagStyle} />
        default :
            return <></>
    }
}

export default RenderFlag

