import React, { useEffect } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "./TabPanel";
import CircularProgress from '@material-ui/core/CircularProgress';

import GetAppIcon from '@material-ui/icons/GetApp';
import csvIcon from "../img/csv-icon.png"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {Link} from "react-router-dom";
import CryptoJS from "crypto-js"

import RenderFlag from "../helper/RenderFlag"
import Utils from "../helper/utils"

const useStyles = makeStyles({
    homeTitle: {
        padding: "0px 0 20px 0",
        paddingLeft: "40px"
    },
    contentRow: {
        display: "flex"
    },
    loader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100vh - 154px)"
    }
});

const StyledTableCell = withStyles((theme) => ({
    body: {
       borderBottom: "1px solid #ccc",
    }
}))(TableCell);

const ReportList = (props) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [countryData, setCountryData] = React.useState(null);
    const [reportType, setReportType] = React.useState(null);

    const getData = async (client, year) => {
        let filename = "reportList.txt"
        if(year !== "2021") {
            filename = filename.split(".")[0] + year + "." +filename.split(".")[1]
        }
        console.log("[debug] -> ",filename)
        return await fetch(
            `data/${client}/${filename}`,
            {
                headers: {
                    'Content-Type': 'application/text',
                    'Accept': 'application/text'
                }
            }
        )
        .then((response) => {
            return response.text();
        })
    }

    useEffect(() => {
        getData(props.client, props.yearTab[activeTab]).then(res => {
            const bytes  = CryptoJS.AES.decrypt(res, props.privateKey);
            const decryptedData = JSON.parse(bytes.toString((CryptoJS.enc.Utf8)));
            setData(decryptedData)
        })
    }, [props.client, props.privateKey, activeTab])

    useEffect(() => {
        const indexCountry = data.findIndex(
            (item) => {
                return item.country === props.country
            }
        );
        setCountryData(data[indexCountry])

        if(data.length > 0) {
            setReportType(Object.keys(data[indexCountry].data))
        }

    }, [data, props.country])

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <>
            {props.country && countryData ? (
                <Container maxWidth="lg" style={{paddingBottom: "50px"}}>
                    <Button
                        size="large"
                        startIcon={<ArrowBackIcon />}
                        style={{marginLeft:"30px"}}
                    >
                        <Link to="/">Back</Link>
                    </Button>
                    <div className={classes.homeTitle}>
                        <Typography variant="h3" component="h3" style={{ textTransform: "capitalize" }}>
                            <strong>{props.country} Report</strong>
                        </Typography>
                    </div>
                    <Grid item xs={12}>
                        <Card variant="outlined" sx={{boxShadow: 1}}>
                            <Tabs
                                value={activeTab}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                {
                                    props.yearTab.map((year, index) => {
                                        return (
                                            <Tab label={year} />
                                        )
                                    })
                                }
                            </Tabs>
                            {
                                props.yearTab.map((year, index) => {
                                    return (
                                        <TabPanel value={activeTab} index={index}>
                                            <CardContent >
                                                <Table size="small">

                                                    {
                                                        reportType && reportType.map((report) => {
                                                            return (
                                                                <>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                {Utils.camelToCapital(report)}
                                                                            </TableCell>
                                                                            <TableCell width="30%">Last Modified</TableCell>
                                                                            <TableCell align="right"></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        { countryData && countryData.data[report].map((item) => {
                                                                            return (
                                                                                <TableRow>
                                                                                    <StyledTableCell>
                                                                                        <div className={classes.contentRow}>
                                                                                            <div style={{marginRight: "5px"}}>
                                                                                                <img src={csvIcon} width="17" alt="CSV"/>
                                                                                            </div>
                                                                                            <div style={{marginRight: "5px"}}>
                                                                                                <RenderFlag country={props.country} width={26}  />
                                                                                            </div>
                                                                                            <div>
                                                                                                { `${item.name} - ${item.month}`}
                                                                                            </div>
                                                                                        </div>
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell>
                                                                                        <div className={classes.contentRow}>
                                                                                            { item.lastModified  }
                                                                                        </div>
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell align="right">
                                                                                        <Link to={`/data/${props.client}/csv/${item.link}`} target="_blank" download>
                                                                                            <Button
                                                                                                sx={{boxShadow: 1}}
                                                                                                color="primary"
                                                                                                size="small"
                                                                                                variant="contained"
                                                                                                startIcon={<GetAppIcon />}
                                                                                            >
                                                                                                Download
                                                                                            </Button>
                                                                                        </Link>
                                                                                    </StyledTableCell>

                                                                                </TableRow>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Table>
                                            </CardContent>
                                        </TabPanel>
                                    )
                                })
                            }
                        </Card>
                    </Grid>
                </Container>
            ) : (
                <div>
                    <div className={classes.loader}>
                        <CircularProgress color="secondary" /> <br/>
                        <div>Loading</div>
                    </div>
                </div>
            )
            }
        </>
    )
}

export default ReportList