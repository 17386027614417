import React, {useContext} from "react";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Button } from "@material-ui/core";

import {Link} from "react-router-dom";

import {GlobalDataProvider} from "../index";

import RenderFlag from "../helper/RenderFlag"

const useStyles = makeStyles({
    homeTitle: {
        padding: "20px 0",
        paddingLeft: "20px",
        textAlign: "center",
        marginBottom: "20px"
    },
    countryBox: {
        textAlign: "center"
    },
    reportLink: {
        textDecoration: "none",
        margin: "0 auto",
        "& button": {
            color: "#515151",
        }
    },
    cardAction: {
        padding: "10px 0",
        borderTop: "1px solid #cccccc"
    },
    flagStyle: {
        margin: "0 auto"
    },
    container: {
        marginTop: "150px"
    }
});

const Home = () => {
    const classes = useStyles();
    const { globalData } = useContext(GlobalDataProvider);

    return (
        <div className={classes.container}>
            <Container maxWidth="lg" >
                <div className={classes.homeTitle}>
                    <Typography variant="h5" component="h5">
                        Welcome to Eureka Market Intelligence
                    </Typography>
                </div>

                <Grid container spacing={2}>
                    {
                        globalData && globalData.countries.map((country) => {
                            return (
                                <Grid item xs={3}>
                                    <Card variant="outlined" sx={{ boxShadow: 1 }}>
                                        <CardContent className={classes.countryBox}>
                                            <RenderFlag country={country} width={60} height={46} />
                                            <Typography variant="h5" component="h2" style={{textTransform: "capitalize"}}>
                                                {country}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.cardAction}>
                                            <Link to={`/${country}`} className={classes.reportLink}>
                                                <Button color="primary" size="small">View Report</Button>
                                            </Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </div>
    )
}

export default Home